import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import 'leaflet-rotatedmarker';
import { aircraftIcons, aircraftTypeMappings, aircraftSizeMapping, aircraftSizeClassifications } from '../../assets/images/aircraftIcons';
import ATCSectorLayer from './ATCSectorLayer';
import AirportMarkers from './AirportMarkers';
import MapControls from './MapControls';
import './VatsimMap.css';

const preloadIcons = () => {
  Object.values(aircraftIcons).forEach(icon => {
    const img = new Image();
    img.src = icon;
  });
};

const getAircraftIcon = (aircraftType, heading) => {
  const mappedType = aircraftTypeMappings[aircraftType?.toUpperCase()] || 'defaultIcon';
  const iconPath = aircraftIcons[mappedType];

  if (!iconPath) {
    console.warn(`No icon found for aircraft type: ${aircraftType}. Using default icon.`);
    return new L.Icon({
      iconUrl: aircraftIcons['defaultIcon'], // Fallback to default icon
      iconSize: [25, 41],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
      rotationAngle: heading
    });
  }

  // Determine size classification and scaling
  const sizeClass = aircraftSizeMapping[mappedType] || 'small';
  const scaleFactor = aircraftSizeClassifications[sizeClass] || 1;

  const img = new Image();
  img.src = iconPath;

  const iconWidth = img.width * scaleFactor;
  const iconHeight = img.height * scaleFactor;

  const adjustedHeading = (heading + 0) % 360;

  return new L.Icon({
    iconUrl: iconPath,
    iconSize: [iconWidth, iconHeight],
    iconAnchor: [iconWidth / 2, iconHeight / 2],
    popupAnchor: [0, -iconHeight / 2],
    className: 'aircraft-icon',
    iconAngle: adjustedHeading,
    rotationOrigin: 'center center',
  });
};

const PlaneMarkers = ({ pilots, showPlanes }) => {
  return (
    <>
      {showPlanes && pilots.map(pilot => (
        <Marker
          key={`${pilot.cid}-${pilot.heading}`}
          position={[pilot.latitude, pilot.longitude]}
          icon={getAircraftIcon(pilot.flight_plan?.aircraft_short, pilot.heading)}
          rotationAngle={(pilot.heading || 0) % 360}
          rotationOrigin="center"
        >
          <Popup>
            <strong>Callsign:</strong> {pilot.callsign} <br />
            <strong>Pilot:</strong> {pilot.name} <br />
            <strong>Aircraft Type:</strong> {pilot.flight_plan?.aircraft_short || 'Unknown'} <br />
            <strong>Altitude:</strong> {pilot.altitude} ft <br />
            <strong>Ground Speed:</strong> {pilot.groundspeed} kts <br />
            <strong>Heading:</strong> {pilot.heading}° <br />
          </Popup>
        </Marker>
      ))}
    </>
  );
};

const VatsimMap = () => {
  const [pilots, setPilots] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showPlanes, setShowPlanes] = useState(true);
  const [showAirports, setShowAirports] = useState(true);
  const [showICAOs, setShowICAOs] = useState(true);

  useEffect(() => {
    // Preload icons before rendering the map
    preloadIcons();

    const fetchVatsimData = async () => {
      try {
        const response = await axios.get('https://data.vatsim.net/v3/vatsim-data.json');
        setPilots(response.data.pilots);
        setLoading(false);

      } catch (err) {
        console.error('Error fetching VATSIM data:', err);
        setLoading(false);
      }
    };

    fetchVatsimData();

    const interval = setInterval(fetchVatsimData, 15000); // Refresh data every 15 seconds
    return () => clearInterval(interval);
  }, []);

  if (loading) {
    return <div>Loading map and data...</div>;
  }

  const bounds = L.latLngBounds(
    L.latLng(-60, -180),
    L.latLng(80, 180)
  );

  return (
    <MapContainer
      center={[30, 0]}
      zoom={2.5}
      minZoom={2}
      maxZoom={18}
      maxBounds={bounds}
      maxBoundsViscosity={1.0}
      style={{ height: '100vh', width: '100%' }}
      zoomControl={false}
      zoomDelta={1}
      wheelPxPerZoomLevel={200}
      wheelDebounceTime={1}
      preferCanvas={true}
      attributionControl={false} // Disable the Leaflet attribution control
    >
      <TileLayer
        url="https://{s}.basemaps.cartocdn.com/dark_nolabels/{z}/{x}/{y}{r}.png"
        tileSize={256}
        updateWhenIdle={true}
      />
      <ATCSectorLayer />
      <AirportMarkers pilots={pilots} showAirports={showAirports} showICAOs={showICAOs} />
      {!loading && <PlaneMarkers pilots={pilots} showPlanes={showPlanes} />}
      <MapControls 
        showPlanes={showPlanes} 
        setShowPlanes={setShowPlanes} 
        showAirports={showAirports} 
        setShowAirports={setShowAirports}
        showICAOs={showICAOs}
        setShowICAOs={setShowICAOs}
      />
    </MapContainer>
  );
};

export default VatsimMap;
