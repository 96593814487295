// src/components/map/MapControls.js

import React from 'react';
import './MapControls.css';

const MapControls = ({ showPlanes, setShowPlanes, showAirports, setShowAirports, showICAOs, setShowICAOs }) => {
  return (
    <div className="map-ui-container">
      <button
        className={`map-ui-button ${showPlanes ? 'active' : ''}`}
        onClick={() => setShowPlanes(!showPlanes)}
      >
        ✈️ {/* Airplane emoji for the plane button */}
      </button>
      <button
        className={`map-ui-button ${showAirports ? 'active' : ''}`}
        onClick={() => setShowAirports(!showAirports)}
      >
        <div className="airport-icon" />
      </button>
      <button
        className={`map-ui-button karb-button ${showICAOs ? 'active' : ''}`}
        onClick={() => setShowICAOs(!showICAOs)}
      >
        KARB
      </button>
    </div>
  );
};

export default MapControls;
