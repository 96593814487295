import React, { useEffect, useState } from 'react';
import { Marker } from 'react-leaflet';
import L from 'leaflet';
import Papa from 'papaparse';
import airportsData from '../../assets/data/airports.csv';

const AirportMarkers = ({ pilots, showAirports, showICAOs }) => {
  const [airports, setAirports] = useState({});

  useEffect(() => {
    Papa.parse(airportsData, {
      download: true,
      header: true,
      complete: (result) => {
        const airportsByICAO = result.data.reduce((acc, airport) => {
          acc[airport.icao] = airport;
          return acc;
        }, {});
        setAirports(airportsByICAO);
      },
    });
  }, []);

  const airportMarkers = pilots.reduce((acc, pilot) => {
    const { departure, arrival } = pilot.flight_plan || {};

    [departure, arrival].forEach(icao => {
      if (airports[icao]) {
        const lat = parseFloat(airports[icao].lat);
        const lon = parseFloat(airports[icao].lon);
        if (!isNaN(lat) && !isNaN(lon)) {
          acc[`${lat},${lon}`] = acc[`${lat},${lon}`] || {
            position: [lat, lon],
            icao,
          };
        }
      }
    });

    return acc;
  }, {});

  return (
    <>
      {Object.values(airportMarkers).map((marker, index) => {
        const markerHtml = `
          <div style="text-align: center;">
            ${showAirports ? '<div style="background-color: gray; width: 5px; height: 5px; border-radius: 50%; margin: 0 auto;"></div>' : ''}
            ${showICAOs ? `<div style="color: #bbb9ba; font-size: 10px; text-align: center; margin-top: 2px;">${marker.icao}</div>` : ''}
          </div>
        `;

        if (!showAirports && !showICAOs) {
          return null;
        }

        const icon = new L.DivIcon({
          className: 'custom-div-icon',
          html: markerHtml,
          iconSize: [20, 20],
          iconAnchor: [10, 10], // Center the icon and text
        });

        return (
          <Marker key={index} position={marker.position} icon={icon} />
        );
      })}
    </>
  );
};

export default AirportMarkers;
