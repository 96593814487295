import React, { useEffect, useState } from 'react';
import { GeoJSON } from 'react-leaflet';

const ATCSectorLayer = () => {
  const [parsedData, setParsedData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('./FIRBoundaries.dat'); // Path to your .dat file
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.text();
        const parsedData = parseFIRData(data);
        setParsedData(parsedData);
      } catch (error) {
        console.error('Error fetching FIR data:', error);
      }
    };

    fetchData();
  }, []);

  const parseFIRData = (data) => {
    const lines = data.trim().split('\n');
    const features = [];
    let currentFeature = null;

    lines.forEach(line => {
      const parts = line.split('|');

      // Detect the start of a new feature
      if (parts.length === 10) {
        // Save the previous feature if it exists
        if (currentFeature && currentFeature.geometry.coordinates[0].length > 0) {
          features.push(currentFeature);
        }

        // Start a new feature
        currentFeature = {
          type: "Feature",
          properties: {
            id: parts[0],
            oceanic: parts[1],
            otherProperty1: parts[2],
            otherProperty2: parts[3],
          },
          geometry: {
            type: "Polygon",
            coordinates: [[]]
          }
        };
      } else if (currentFeature && parts.length === 2) {
        // Add coordinates to the current feature
        const [lat, lon] = line.split('|').map(Number);
        if (!isNaN(lat) && !isNaN(lon)) {
          currentFeature.geometry.coordinates[0].push([lon, lat]);
        }
      }
    });

    // Add the last feature
    if (currentFeature && currentFeature.geometry.coordinates[0].length > 0) {
      features.push(currentFeature);
    }

    //console.log("Parsed features:", features);

    return {
      type: "FeatureCollection",
      features: features
    };
  };

  // Empty function to avoid popups
  const onEachFeature = (feature, layer) => {
    // Do nothing to avoid popups
  };

  const style = {
    color: '#2f2f2f', // Dark gray outline
    weight: 1,
    opacity: 1,
    fillOpacity: 0, // No fill inside
  };

  return parsedData ? (
    <GeoJSON data={parsedData} style={style} onEachFeature={onEachFeature} />
  ) : null;
};

export default ATCSectorLayer;
